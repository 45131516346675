import Alpine from "alpinejs";
import { tns } from "tiny-slider";

export function Sliders()
{
    Alpine.data("Sliders", () => ({

        testimonialsSlider: null,
        heroSlider: null,
        whatWeCanDo: null,
        caseStudyGalleryImages: null,
        caseStudyBoxes: null,
        exampleCarousel: null,

        Testimonials() {
                this.testimonialsSlider = tns({
                    container: "#TestimonialsSlider",
                    items: 1,
                    autoHeight: true, // Enable auto height adjustment
                    nav: false,
                    controls: false,
                    mode: 'gallery',
                    lazyload: true,
                  // TNS configuration options here
                });
        },

        TestimonialsPrevSlide() {
            if (this.testimonialsSlider) {
                this.testimonialsSlider.goTo("prev");
            }
        },

        TestimonialsNextSlide() {
            if (this.testimonialsSlider) {
                this.testimonialsSlider.goTo("next");
            }
        },

        Hero() {
              this.heroSlider = tns({
                  container: "#HeroSlider",
                  items: 1,
                  nav: false,
                  controls: false,
                  mode: 'gallery',
                  autoplay: true,
                  autoplayTimeout: 5000,
                  mouseDrag: true,
                  autoplayButtonOutput: false,
                  lazyload: true,
              });
        },

        HeroPrevSlide() {
            if (this.heroSlider) {
                this.heroSlider.goTo("prev");
            }
        },

        HeroNextSlide() {
            if (this.heroSlider) {
                this.heroSlider.goTo("next");
            }
        },

        WhatWeCanDoGallery() {
            this.whatWeCanDo = tns({
              container: "#WhatWeCanDoGallery",
              items: 2,
              nav: false,
              controls: false,
              gutter: 10,
              lazyload: true,
              responsive: {
                768: {
                  items: 3
                },
                1024: {
                  items: 5
                }
              }
            });
        },

        WhatWeCanDoPrevSlide() {
          if (this.whatWeCanDo) {
            this.whatWeCanDo.goTo("prev");
          }
        },

        WhatWeCanDoNextSlide() {
          if (this.whatWeCanDo) {
            this.whatWeCanDo.goTo("next");
          }
        },


        CaseStudiesGallery() {
            this.caseStudyGalleryImages = tns({
                container: "#CaseStudyCarousel",
                items: 5,
                nav: false,
                controls: false,
                gutter: 10,
                edgePadding: '10px',
                mouseDrag: true,
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayButtonOutput: false,
                responsive: {
                  0: {
                    items: 2,
                    mouseDrag: true,
                  },
                  780: {
                    items: 3
                  },
                  1180: {
                    items: 4
                  },
                  1580: {
                    items: 5
                  }
                }
            });
        },


        CaseStudiesGalleryNextSlide() {
          if (this.caseStudyGalleryImages) {
            this.caseStudyGalleryImages.goTo("next");
          }
        },


      CaseStudyBoxes() {
          this.caseStudyBoxes = tns({
              container: "#CaseStudyBoxes",
              items: 1,
              nav: false,
              controls: false,
              lazyload: true,
          });
        },

        CaseStudyPrevSlide() {
            if (this.caseStudyBoxes) {
                this.caseStudyBoxes.goTo("prev");
            }
        },

        CaseStudyNextSlide() {
            if (this.caseStudyBoxes) {
                this.caseStudyBoxes.goTo("next");
            }
        },


        ExampleImagesGallery() {
          this.exampleCarousel = tns({
            container: "#ExampleImages",
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayButtonOutput: false,
            items: 5,
            nav: false,
            controls: false,
            edgePadding: 0,
            gutter: 10,
            mouseDrag: true,
            lazyload: true,
            responsive: {
              0: {
                items: 1
              },
              780: {
                items: 3
              },
              1180: {
                items: 4
              },
              1580: {
                items: 5
              }
            }
          });
        },

        ExampleImagesGalleryNextSlide() {
          if (this.exampleCarousel) {
            this.exampleCarousel.goTo("next");
          }
        },




    }));
}

import domReady from '@roots/sage/client/dom-ready';
import Alpine from 'alpinejs';
import {MobileMenu} from "@scripts/MobileMenu.js";
import {Sliders} from "@scripts/modules/sliders.js";
import {General} from "@scripts/modules/general.js";
import {LightboxFunctionality} from "@scripts/modules/lightbox.js";



/**
 * Application entrypoint
 */
domReady(async() => {
    window.Alpine = Alpine;
    LightboxFunctionality();
    General();
    Sliders();
    Alpine.start();
    MobileMenu();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) {
    import.meta.webpackHot.accept(console.error);
}

import Alpine from "alpinejs";



export function General()
{
    Alpine.data('LazyLoad', () => ({
        loaded: false,
        intersected: false,
        observe() {
          const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.loaded = true;
                this.intersected = true;
                observer.unobserve(entry.target);
              }
            });
          });
          observer.observe(this.$el);
        },
        elementInViewport(el) {
          var top = el.offsetTop;
          var left = el.offsetLeft;
          var width = el.offsetWidth;
          var height = el.offsetHeight;
          while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
          }
          return top < window.pageYOffset + window.innerHeight && left < window.pageXOffset + window.innerWidth && top + height > window.pageYOffset && left + width > window.pageXOffset;
        },
        show() {
          if (this.elementInViewport(this.$el)) {
            this.$el.classList.add('show');
          }
        }
}));

}

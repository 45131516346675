import Mmenu from "mmenu-js";

export function MobileMenu() {
  let MobileMenu = document.getElementById('MobileMenu');
  if(MobileMenu) {
    new Mmenu( "#MobileMenu", {
      // options
      extensions: ["position-left", "position-front"],
      navbar: {
        title: '<div class="logo-container mb-5 mt-5"><a href="/"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="149.961" height="59.383" viewBox="0 0 149.961 59.383"><path id="Path_4503" data-name="Path 4503" d="M49.076,0V10.223l4.891,4.891L68.545,29.689l-14.6,14.59,0,0-4.864,4.862V59.383H60.208L82.362,37.23V22.151L60.208,0Z" transform="translate(-30.519 0)" fill="#24b7cd"></path><path id="Path_4504" data-name="Path 4504" d="M0,0V59.383H14.846V0Z" transform="translate(0 0)" fill="#24b7cd"></path> <g id="Group_1522" data-name="Group 1522" transform="translate(0 0)"> <g id="Group_1521" data-name="Group 1521" clip-path="url(#clip-path)"> <path id="Path_4505" data-name="Path 4505" d="M156.654,63.884V19.63h12.17q4.777,0,7.191,2.653t2.414,7.757V50.759q0,6.285-2.2,9.705t-7.707,3.42Zm8.9-7.82h1.533a2.123,2.123,0,0,0,2.439-2.363V30.869a4.581,4.581,0,0,0-.591-2.854,3.294,3.294,0,0,0-2.4-.641h-.981Z" transform="translate(-97.417 -12.207)" fill="#001e37"></path> <path id="Path_4506" data-name="Path 4506" d="M217.353,63.884l4.274-44.254h15.012l4.2,44.254h-8.373l-.629-7.141h-5.33l-.553,7.141Zm9.781-14.206h4.023l-1.936-22.5h-.4Z" transform="translate(-135.163 -12.207)" fill="#001e37"></path><path id="Path_4507" data-name="Path 4507" d="M286.1,63.884V19.63h8.851V56.341h9.1v7.543Z" transform="translate(-177.912 -12.207)" fill="#001e37"></path> <path id="Path_4508" data-name="Path 4508" d="M336.954,63.884V19.63h17.7v8.549h-8.7V36.8H354.3v8.323h-8.347V55.26h9.278v8.624Z" transform="translate(-209.538 -12.207)" fill="#001e37"></path><path id="Path_4509" data-name="Path 4509" d="M386.259,20h-.579V19.63h1.586V20h-.573v1.674h-.433Zm2.376.831.942-1.206h.222v2.048h-.416V20.514l-.673.86h-.146l-.667-.86v1.165h-.416V19.63h.222Z" transform="translate(-239.839 -12.207)" fill="#001e37"></path></g></g></svg>\n</a></div>'
      }
    }, {
      // configuration
      offCanvas: {
        page: {
          selector: "#app"
        }
      },
    });
  }
}

import Alpine from "alpinejs";
import { lightbox } from 'lightbox2';
export function LightboxFunctionality()
{
  Alpine.data("LightBox", () => ({

    GalleryImages() {
      const lightbox = require('lightbox2');
      lightbox.option({
        'resizeDuration': 200,
        'wrapAround': true
      })
    },

  }));

}
